<template>
    <div>
        <section class="form">
            <div class="form-container">
                <div class="form-form" style="width: 100%">
                    <div class="form-form-wrap">
                        <div class="form-container">
                            <div class="form-content" >
                                <transition name="fade">
                                    <div>
                                        <h3 class="text-center font-weight-bold">{{ config.appName }}</h3>
                                        <h4 class="text-center">Reset your password</h4>
                                        
                                        <form class="text-left" @submit.prevent="onSubmit">
                                            <div class="form mt-5">
                                                <validation-errors :errors="validationErrors"></validation-errors>
                                                <div id="password-field" class="field-wrapper input mb-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
                                                    <input id="password" name="password" :type="password_field" class="form-control" placeholder="Enter your new password" v-model="password" required>
                                                </div>
                                                <!-- <div class="field wrapper input mb-2">
                                                    <input type="text" v-model="reset_token" class="form-control">
                                                </div> -->
                                                <div class="d-sm-flex justify-content-between">
                                                    <div class="field-wrapper toggle-pass">
                                                        <p class="d-inline-block">Show Password</p>
                                                        <label class="switch s-warning">
                                                            <input type="checkbox" id="toggle-password" class="d-none" @click="togglePassword">
                                                            <span class="slider round"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="field-wrapper mt-5">
                                                    <button type="submit" class="btn btn-warning">Reset password</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </transition>
                                <p class="terms-conditions">© {{ new Date().getFullYear() }} All Rights Reserved. <a href="/">{{ config.appName }}</a> </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { useToast } from 'vue-toastification';
import '../../../public/assets/vendor/js/authentication/form-1.js'
import config from "../../appconfig";
import ValidationErrors from '../../components/ValidationErrors.vue';
import Auth from '../../helpers/auth.js';
import instance from '../../presenter/api/network.js';
export default {
    components: {ValidationErrors},
    mounted(){
        
    },
    data(){
        return {
            password_field: "password",
            password: "",
            config: config,
            validationErrors: "",
            reset_token: this.$route.query.confirmation
        }
    },
    methods: {
        togglePassword(){
            if (this.password_field == 'password'){
                this.password_field = "text";
            }else{
                this.password_field = "password";
            }
        },
        onSubmit(){
            let data = {
                password: this.password,
                login_method: "email_and_password",
                confirmation_token: this.reset_token,
                // device_id: Math.random().toString(36)
            }
            this.$root.startRequestLoader();
            
            instance.post("customer/complete-password-reset", data).then(res => {
                
                this.$root.endRequestLoader();
                useToast().success("Your password has been reset successfully, login now");
                this.$router.push("/login")
                
            }).catch(err => {
                this.$root.endRequestLoader();
                if (err.response.status == 400) {
                    
                    this.validationErrors = err.response.data.data;
                } else if (err.response.status == 401) {
                    toastr.error("The reset token is either invalid, or has expired");
                }
            })
        }
    }
}
</script>

<style scoped>
@import url('../../../public/assets/vendor/css/authentication/form-1.css');
@import url('../../../public/assets/vendor/css/forms/theme-checkbox-radio.css');
@import url('../../../public/assets/vendor/css/forms/switches.css');
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
